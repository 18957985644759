const defaultFontFamily =
	'-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'

const palette = {
	primary: { main: '#4075ed' },
	secondary: { main: '#2F2D2E' },
	yellow: { main: '#F4E049' },
	green: { main: '#40ED83' },
	charcoal: { main: '#363537' },
	silver: { main: '#f8f8f8' },
	red: { main: '#db5440' },
	sky: { main: '#eef2fd' },
}

const shape = {
	borderRadius: 12,
}

const typography = {
	fontFamily: defaultFontFamily,
	fontSize: 16,
	h1: { fontFamily: defaultFontFamily, fontWeight: 700 },
	h2: { fontFamily: defaultFontFamily, fontWeight: 700 },
	h3: { fontFamily: defaultFontFamily, fontWeight: 700 },
	h4: { fontFamily: defaultFontFamily, fontWeight: 700 },
	h5: { fontFamily: defaultFontFamily, fontWeight: 700 },
	h6: { fontFamily: defaultFontFamily, fontWeight: 700 },
	subtitle1: { fontFamily: defaultFontFamily },
	subtitle2: { fontFamily: defaultFontFamily },
	body1: { fontFamily: defaultFontFamily },
	body2: { fontFamily: defaultFontFamily },
	caption: { lineHeight: 1.7 },
}

const defaultTheme = {
	typography,
	palette,
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: shape.borderRadius * 10,
					fontWeight: 700,
					textTransform: 'capitalize',
				},
				containedPrimary: {
					border: '2px solid transparent',

					'&:hover': {
						backgroundColor: 'transparent',
						border: `2px solid ${palette.primary.main}`,
						color: palette.primary.main,
					},
				},
				containedSizeLarge: {
					padding: '12px 32px',
				},
			},
		},
		MuiContainer: {
			defaultProps: {
				maxWidth: 'md',
			},
		},
	},
	shape,
}

export default defaultTheme
