import merge from 'lodash/merge'

import {
	createTheme,
	emphasize,
	responsiveFontSizes,
} from '@mui/material/styles'

import defaultThemeConfig from '../../config/theme'

const darkTheme = responsiveFontSizes(
	createTheme(
		merge({}, defaultThemeConfig, {
			palette: {
				background: {
					default: '#0f1724',
					emphasis: emphasize('#0f1724', 0.2),
					landingPage: emphasize('#0f1724', 0.2),
					footer: emphasize('#0f1724', 0.2),
				},
				mode: 'dark',
			},
		})
	)
)

export default darkTheme
