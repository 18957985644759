import React, { useContext, useEffect, useState } from 'react'

import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { CssBaseline, useMediaQuery } from '@mui/material'

import { darkTheme, lightTheme } from '../../src/themes'

const UserThemeContext = React.createContext('light')
const UserThemeDispatch = React.createContext()

const themeMap = {
	dark: darkTheme,
	light: lightTheme,
}

const UserThemeProvider = ({ children }) => {
	const isDark = useMediaQuery('(prefers-color-scheme: dark)')
	const [theme, setTheme] = useState(isDark ? 'dark' : 'light')

	useEffect(() => {
		setTheme(isDark ? 'dark' : 'light')
	}, [isDark])

	const handleSetTheme = theme => {
		if (themeMap[theme]) {
			setTheme(theme)
		} else {
			setTheme(isDark ? 'dark' : 'light')
		}
	}

	return (
		<UserThemeContext.Provider value={theme}>
			<UserThemeDispatch.Provider value={handleSetTheme}>
				<StyledEngineProvider injectFirst>
					<ThemeProvider theme={themeMap[theme]}>
						<CssBaseline />
						{children}
					</ThemeProvider>
				</StyledEngineProvider>
			</UserThemeDispatch.Provider>
		</UserThemeContext.Provider>
	)
}

const useUserTheme = () => {
	const theme = useContext(UserThemeContext)
	if (typeof theme === 'undefined') {
		throw new Error('useUserTheme must be used within a UserThemeContext')
	}
	return theme
}

const useSetUserTheme = () => {
	const setTheme = useContext(UserThemeDispatch)
	if (typeof setTheme === 'undefined') {
		throw new Error(
			'useSetUserTheme must be used within a UserThemeDispatch'
		)
	}
	return setTheme
}

export {
	UserThemeContext,
	UserThemeDispatch,
	UserThemeProvider,
	useSetUserTheme,
	useUserTheme,
}
