import React from 'react'
import { Helmet } from 'react-helmet'
import { QueryClient, QueryClientProvider } from 'react-query'

import { UserThemeProvider } from '../../src/themes/userThemeContext'
import loadDevtools from '../../src/devtools/load'

const Devtools = loadDevtools()
const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: 1000 * 60 * 10, // 10 minute
		},
	},
})

const TopLayout = ({ children }) => {
	return (
		<>
			<Helmet>
				<meta
					name={'viewport'}
					content={
						'minimum-scale=1, initial-scale=1, width=device-width'
					}
				/>
			</Helmet>
			<UserThemeProvider>
				<QueryClientProvider client={queryClient}>
					{children}
					<Devtools />
				</QueryClientProvider>
			</UserThemeProvider>
		</>
	)
}

export default TopLayout
