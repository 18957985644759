import loadable from '@loadable/component'

const loadDevtools = () => {
	// This allows you to explicitly disable it in development for example
	const explicitlyDisabled =
		typeof window !== 'undefined'
			? window.location.search.includes('devtools=false') ||
			  window.localStorage.getItem('devtools') === 'false'
			: false

	const explicitlyEnabled =
		typeof window !== 'undefined'
			? window.location.search.includes('devtools=true') ||
			  window.localStorage.getItem('devtools') === 'true'
			: false

	// We want it enabled by default everywhere but production and we also want
	// to support the devtools in production (to make us more productive
	// triaging production issues).
	// You can enable the Devtools via localStorage or the query string.
	if (
		!explicitlyDisabled &&
		(process.env.NODE_ENV === 'development' || explicitlyEnabled)
	) {
		// Use a dynamic import so the devtools code isn't bundled with the
		// regular app code so we don't worry about bundle size.
		return loadable(() => import('./devtools'))
	} else {
		// If we don't need the Devtools, do nothing.
		return () => null
	}
}

export default loadDevtools
